import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {register} from './serviceWorkerRegistration';
import {onServiceWorkerUpdate} from '@3m1/service-worker-updater';
import './i18n';
import './sentry';
import {ErrorBoundary} from '@sentry/react';
import Error from './pages/Error';
import './lib/polyfill';
// import './wdyr';
import MatomoProvider from './components/MatomoProvider';

window.addEventListener('vite:preloadError', event => {
    event.preventDefault();
    window.location.reload();
});

declare global {
    interface Window {
        global: any;
    }
}

window.global ||= window;

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <ErrorBoundary fallback={({error}) => <Error error={error as Error} />}>
            <MatomoProvider>
                <App />
            </MatomoProvider>
        </ErrorBoundary>
    </React.StrictMode>,
);

register({
    onUpdate: onServiceWorkerUpdate,
});
