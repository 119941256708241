import {ImageCrop} from '../../components/Admin/Form/Picture/PictureCropper';
import {Area} from 'react-easy-crop';
import {ApiFile} from '../../types/api';
import Api from '../../lib/apiClient';
import {clearEntityCache} from './CRUD';

export async function uploadEntityFile<T>(
    entity: string,
    type: string,
    id: string,
    file: ImageCrop,
    area?: Area,
): Promise<T> {
    const form = new FormData();
    form.append('file', file);
    if (area) {
        form.append('area', JSON.stringify(area));
    }

    const res = (
        await Api.post(`/${entity}/${id}/${type}`, form, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    ).data;

    clearEntityCache(entity, id);

    return res;
}

export async function deleteEntityFile<T>(
    entity: string,
    type: string,
    id: string,
): Promise<T> {
    const res = (await Api.put(`/${entity}/${id}/delete-${type}`)).data;
    clearEntityCache(entity, id);

    return res;
}

export async function uploadLogo(
    file: ImageCrop,
    area?: Area,
): Promise<ApiFile> {
    const form = new FormData();
    form.append('file', file);
    if (area) {
        form.append('area', JSON.stringify(area));
    }

    return (
        await Api.post(`/upload/logo`, form, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    ).data;
}

export async function uploadCover(
    file: ImageCrop,
    area?: Area,
): Promise<ApiFile> {
    const form = new FormData();
    form.append('file', file);
    if (area) {
        form.append('area', JSON.stringify(area));
    }

    return (
        await Api.post(`/upload/cover`, form, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    ).data;
}

export async function uploadAvatar(
    file: ImageCrop,
    area?: Area,
): Promise<ApiFile> {
    const form = new FormData();
    form.append('file', file);
    if (area) {
        form.append('area', JSON.stringify(area));
    }

    return (
        await Api.post(`/upload/avatar`, form, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    ).data;
}
