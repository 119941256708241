import React, {PropsWithChildren} from 'react';
import {
    MatomoProvider as MatomoP,
    createInstance,
} from '@jonkoops/matomo-tracker-react';
import {config} from '../config';

type Props = PropsWithChildren<{}>;

export default function MatomoProvider({children}: Props) {
    const instance = createInstance({
        urlBase: config.matomoUrl,
        siteId: parseInt(config.matomoSiteId),
        heartBeat: {
            active: true,
            seconds: 10,
        },
        configurations: {
            setSecureCookie: true,
            setCookieSameSite: 'Strict',
        },
    });

    return <MatomoP value={instance}>{children}</MatomoP>;
}
