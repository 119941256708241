import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Profile} from '../../types/api';
import {getProfile, logProfileView} from '../../services/api/ProfileApi';
import ProfileView from '../../components/Profile/ProfileView';
import {ThemeProvider} from '@mui/material';
import PoweredBy from '../../components/Ui/PoweredBy';
import {createCachedTheme} from '../../components/Ui/themes/ProfileTheme';
import {useProfileHead} from '../../components/useProfileHead';
import {AxiosError} from 'axios';
import MediumDisabled from './MediumDisabled';
import ProfileMenu from '../../components/Profile/ProfileMenu';
import {useTranslation} from 'react-i18next';
import {getLocalStorage} from '../../lib/storage';
import {customCustomerSubDomain} from '../../lib/urlHelper';
import {useMatomo} from '@jonkoops/matomo-tracker-react';

export default function ProfileShow() {
    const {id, safeKey} = useParams();
    const [profile, setProfile] = useState<Profile>();
    const [error, setError] = useState<number>();
    const {i18n} = useTranslation();
    const {trackPageView} = useMatomo();

    React.useEffect(() => {
        trackPageView();
    }, []);

    React.useEffect(() => {
        getLocalStorage()?.removeItem('i18nextLng');
    }, []);

    const theme = React.useMemo(() => {
        return createCachedTheme(
            profile?.resolvedTheme.style ?? 'default',
            profile?.resolvedTheme.color ?? 'default',
            i18n.dir(),
        );
    }, [profile, i18n]);

    useEffect(() => {
        getProfile(id!, safeKey, {
            'params': {
                subdomain: customCustomerSubDomain,
            },
            'handledErrorStatuses': [404],
            'axios-retry': {
                retries: 5,
            },
        }).then(
            profile => {
                setProfile(profile);
                logProfileView(profile.id);
            },
            (e: AxiosError) => {
                if (e.isAxiosError) {
                    setError(e.response?.status);
                }
            },
        );
    }, []);

    useProfileHead(profile);

    if (error && [404, 403].includes(error)) {
        return <MediumDisabled />;
    }

    if (profile && profile.expired) {
        return <MediumDisabled />;
    }

    return (
        <ThemeProvider theme={theme}>
            {profile && <ProfileMenu profile={profile} />}
            <ProfileView profile={profile} safeKey={safeKey!}>
                {profile && !profile.options.noBranding ? (
                    <PoweredBy profileId={profile.id} />
                ) : (
                    ''
                )}
            </ProfileView>
        </ThemeProvider>
    );
}
