import React, {
    ImgHTMLAttributes,
    SyntheticEvent,
    useCallback,
    useEffect,
} from 'react';
import classnames from 'classnames';
import {CircularProgress} from '@mui/material';

type Size = {
    width: number;
    height: number;
};

type OptionalSize = {
    width?: number;
    height?: number;
};

const loadedImages: Record<string, Size> = {};

function computeHeight(
    img: Size,
    width: number,
    minHeight?: number,
    maxHeight?: number,
): number {
    const cH = (img.height * width!) / img.width;

    if (minHeight && cH < minHeight) {
        return minHeight;
    }
    if (maxHeight && cH > maxHeight) {
        return maxHeight;
    }

    return cH;
}

type Props = {
    src: string;
    delay?: number;
    width?: number;
    height?: number;
    minHeight?: number;
    loadingMinHeight?: number;
    maxHeight?: number;
    centered?: boolean;
    rounded?: boolean;
    transparent?: boolean;
    adjustHeightOnload?: boolean;
    onLoad?: () => void;
    imgProps?: ImgHTMLAttributes<HTMLImageElement>;
} & ImgHTMLAttributes<HTMLImageElement>;

export type {Props as LoadingImgProps};

export default function LoadingImg({
    src,
    width,
    height,
    rounded,
    alt,
    minHeight,
    maxHeight,
    onLoad: onLoadHandler,
    loadingMinHeight,
    delay = 300,
    adjustHeightOnload,
    centered,
    transparent,
    imgProps = {},
    ...rest
}: Props) {
    if (adjustHeightOnload && !width) {
        throw new Error(`width must be defined when using adjustHeightOnload`);
    }

    const cachedSize: Size | undefined = loadedImages[src];

    const [loaded, setLoaded] = React.useState(Boolean(cachedSize));
    const [error, setError] = React.useState(false);
    const [displayLoader, setDisplayLoader] = React.useState(false);
    const timeout = React.useRef<ReturnType<typeof setTimeout> | undefined>();
    const refLoaded = React.useRef<string>();

    const defaultSize: OptionalSize =
        adjustHeightOnload && cachedSize
            ? {
                  width: width,
                  height: computeHeight(
                      cachedSize,
                      width!,
                      minHeight,
                      maxHeight,
                  ),
              }
            : {width, height};
    const [size, setSize] = React.useState<OptionalSize>(defaultSize);

    useEffect(() => {
        if (refLoaded.current === src) {
            return;
        }

        setLoaded(false);

        timeout.current = setTimeout(() => setDisplayLoader(true), delay);

        return () => {
            timeout.current && clearInterval(timeout.current);
        };
        // do not rely on delay:
    }, [src]);

    const onLoad = useCallback(
        (e: SyntheticEvent<HTMLImageElement>) => {
            refLoaded.current = src;
            timeout.current && clearTimeout(timeout.current);
            setLoaded(true);

            const target = e.target as HTMLImageElement;
            loadedImages[src] = {
                width: target.width,
                height: target.height,
            };

            if (adjustHeightOnload) {
                setSize({
                    width: width!,
                    height: computeHeight(target, width!, minHeight, maxHeight),
                });
            }

            onLoadHandler && onLoadHandler();
        },
        [src],
    );

    const onError = () => {
        setError(true);
    };

    return (
        <div
            className={classnames(['img-loader'], {
                'loaded': loaded || error,
                'load-error': error,
                'overflowed': adjustHeightOnload,
                'was-long': displayLoader && !error,
                'centered': centered,
                'transparent': transparent,
            })}
            style={{
                width: size.width || '100%',
                height: size.height || '100%',
                minHeight:
                    loadingMinHeight && !loaded ? loadingMinHeight : undefined,
            }}
            {...rest}
        >
            {!loaded && displayLoader && !error && (
                <CircularProgress color="inherit" />
            )}
            {!error && (
                <img
                    src={src}
                    onLoad={onLoad}
                    onError={onError}
                    alt={alt}
                    style={{
                        borderRadius: rounded ? '50%' : undefined,
                    }}
                    {...imgProps}
                    key={src}
                />
            )}
        </div>
    );
}
