import React, {PropsWithChildren, useState} from 'react';
import {Profile} from '../../types/api';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import {
    Box,
    Container,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    useTheme,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import CircleBorder from '../Ui/CircleBorder';
import {useWindowSize} from '@alchemy/react-hooks/src/useWindowSize';
import {config} from '../../config';
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';
import {useA2HS} from '../../hooks/useA2HS';
import appleWallet from '../../assets/images/wallet/add-to-apple-wallet.png';
import googleWallet from '../../assets/images/wallet/add-to-google-wallet.png';
import {
    isAndroid,
    isChrome,
    isDesktop,
    isIOS,
    isSafari,
} from 'react-device-detect';
import {getUtmParams} from '../../lib/tracking';
import {useMatomo} from '@jonkoops/matomo-tracker-react';
import {createStateSetterProxy} from '../../lib/utils';
import {StateSetter} from '../../types/utils';

type Props = PropsWithChildren<{
    profile: Profile;
}>;

export default function ProfileMenu({profile}: Props) {
    const [open, _setOpen] = useState(false);
    const {t} = useTranslation();
    const theme = useTheme();
    const {ready: A2HSReady, prompt: onA2HSClick, accepted} = useA2HS();
    const {innerWidth} = useWindowSize();
    const size = Math.min(innerWidth / 2, 300);
    const buttonBgColor = !open ? `rgba(255,255,255,0.7)` : undefined;
    const {trackEvent} = useMatomo();

    const setOpen = React.useCallback<StateSetter<boolean>>(
        handler => {
            _setOpen(
                createStateSetterProxy(handler, open => {
                    trackEvent({
                        category: 'interaction',
                        action: 'toggle',
                        name: 'menu',
                        value: open ? 1 : 0,
                    });

                    return open;
                }),
            );
        },
        [_setOpen],
    );

    return (
        <>
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                sx={theme => ({
                    'position': 'absolute',
                    'top': 10,
                    'right': 10,
                    'zIndex': theme.zIndex.drawer + 1,
                    'backgroundColor': buttonBgColor,
                    '&:hover': {
                        backgroundColor: buttonBgColor,
                    },
                })}
                onClick={() => setOpen(p => !p)}
            >
                <MenuIcon />
            </IconButton>
            <Drawer anchor={'right'} open={open} onClose={() => setOpen(false)}>
                <CircleBorder color={theme.palette.secondary.main}>
                    <Container
                        maxWidth={'xs'}
                        sx={{
                            pt: 3,
                            pb: 1,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                            }}
                        >
                            <div
                                style={{
                                    borderRadius: '50%',
                                    backgroundColor: '#FFF',
                                    padding: 3,
                                    width: size,
                                    height: size,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <img
                                    style={{
                                        display: 'block',
                                        width: '68%',
                                    }}
                                    src={profile.qrCodeUrl}
                                    alt={'QRCode'}
                                />
                            </div>
                            <Typography
                                variant={'body2'}
                                sx={{
                                    mt: 2,
                                }}
                            >
                                {t(
                                    'app:profile_menu.qrcode.helper',
                                    'Show QRCode to share your profile',
                                )}
                            </Typography>
                        </Box>
                    </Container>
                </CircleBorder>
                <List
                    sx={{
                        mt: 3,
                    }}
                >
                    <ListItem disablePadding>
                        <ListItemButton
                            component={'a'}
                            href={`${config.homeUrl}?${getUtmParams({
                                medium: 'profile',
                                source: 'h-menu',
                                term: profile.id,
                            })}`}
                        >
                            <ListItemIcon>
                                <LocalMallIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={t(
                                    'app:profile_menu.action.buy',
                                    'Get my VKARD',
                                )}
                            />
                        </ListItemButton>
                    </ListItem>
                    {A2HSReady && (
                        <ListItem disablePadding>
                            <ListItemButton
                                onClick={!accepted ? onA2HSClick : undefined}
                                color={accepted ? 'success' : 'undefined'}
                            >
                                <ListItemIcon>
                                    <AddToHomeScreenIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={t(
                                        'app:profile_menu.action.a2hs',
                                        'Add to home screen',
                                    )}
                                />
                            </ListItemButton>
                        </ListItem>
                    )}
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemButton
                            component={'a'}
                            href={config.dashboardUrl}
                        >
                            <ListItemIcon>
                                <AccountCircleIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={t(
                                    'app:profile_menu.action.login',
                                    'Dashboard access',
                                )}
                            />
                        </ListItemButton>
                    </ListItem>
                    {(isIOS || (isDesktop && isSafari)) && (
                        <ListItem>
                            <a
                                style={{
                                    display: 'block',
                                    margin: '0 auto',
                                }}
                                href={profile.walletPassAppleUrl}
                            >
                                <img
                                    style={{
                                        maxHeight: 40,
                                    }}
                                    src={appleWallet}
                                    alt="Apple Wallet"
                                />
                            </a>
                        </ListItem>
                    )}
                    {(isAndroid || (isDesktop && isChrome)) && (
                        <ListItem>
                            {/* eslint-disable-next-line react/jsx-no-target-blank */}
                            <a
                                style={{
                                    display: 'block',
                                    margin: '0 auto',
                                }}
                                href={profile.walletPassGoogleUrl}
                                target={'_blank'}
                            >
                                <img
                                    style={{
                                        maxHeight: 40,
                                    }}
                                    src={googleWallet}
                                    alt="Google Wallet"
                                />
                            </a>
                        </ListItem>
                    )}
                </List>
            </Drawer>
        </>
    );
}
