declare global {
    interface Window {
        config: {
            apiUrl: string;
            dashboardUrl: string;
            googleApiKey: string;
            homeUrl: string;
            profileUrl: string;
            publicS3BaseUrl: string;
            sentryDsn: string;
            sentryEnvironment: string;
            sentryRelease: string;
            soketiKey: string;
            soketiHost: string;
            matomoUrl: string;
            matomoSiteId: string;
        };
    }
}

export const config = window.config;
